enum Localization {
  welcome = 'welcome',
  language = 'language',
  stocktakingIntro = 'stocktakingIntro',
  selectALanguage = 'selectALanguage',
  customer = 'customer',
  customers = 'customers',
  product = 'product',
  products = 'products',
  company = 'company',
  companies = 'companies',
  invoices = 'invoices',
  add = 'add',
  edit = 'edit',
  delete = 'delete',
  areYouSure = 'areYouSure',
  dashboard = 'dashboard',
  brand = 'brand',
  brands = 'brands',
  category = 'category',
  categories = 'categories',
  users = 'users',
  vendor = 'vendor',
  vendors = 'vendors',
  invoicesDashboard = 'invoicesDashboard',
  tracking = 'tracking',
  trackDashboard = 'trackDashboard',
  english = 'english',
  spanish = 'spanish',
  french = 'french',
  attributes = 'attributes',
  attribute = 'attribute',
  warehouse = 'warehouse',
  warehouses = 'warehouses',
  dayOfCutOff = 'dayOfCutOff',
  numberOfPayments = 'numberOfPayments',
  interestToBeAppliedWhenLatePayment = 'interestToBeAppliedWhenLatePayment',
  interestIsPercentage = 'interestIsPercentage',
  applyInterestAutomatically = 'applyInterestAutomatically',
  dayCutOffNotSetWarning = 'cutOffNotSetWarning',
  warning = 'warning',
  every = 'every',
  dayss = 'days',
  noDaysOfCutOff = 'noDaysOfCutOff',
  yes = 'yes',
  no = 'no',
  paymentInInstallments = 'paymentInstallments',
  isNumberOfPaymentOrPaymentInstallments = 'isNumberOfPaymentOrPaymentInstallments',
  thisValueMustBeGreaterThanZero = 'thisValueMustBeGreaterThanZero',
  thisValueMustBeGreaterOrEqualZero = 'thisValueMustBeGreaterOrEqualZero',
  thisValueMustBeGreaterOrEqual = 'thisValueMustBeGreaterOrEqual',
  penaltyToBeAppliedWhenLatePayment = 'penaltyToBeAppliedWhenLatePayment',
  start = 'start',
  within = 'within',
  of = 'of',
  startThePaymentsWithinNDays = 'startThePaymentsWithinNDays',
  penaltyToBeAppliedWhenLatePaymentIsPercentage = 'penaltyToBeAppliedWhenLatePaymentIsPercentage',
  noSettled = 'noSettled',
  saveAndConfirmInvoice = 'saveAndConfirmInvoice',
  justDeactivateTheInvoicePaymentConfiguration = 'justDeactivateTheInvoicePaymentConfiguration',
  or = 'or',
  isInvoiceAlreadyPaid = 'isInvoiceAlreadyPaid',
  everythingSeemsFineHere = 'everythingSeemsFineHere',
  pleaseReviewYourInvoicePaymentConfigurationSettings = 'pleaseReviewYourInvoicePaymentConfigurationSettings',
  pleaseReviewYourTotalInvoice = 'pleaseReviewYourTotalInvoice',
  yourInvoiceHasATotalOfZeroToHaveAPaymentPlanConfigurationMustBeGreaterThanZero = 'yourInvoiceHasATotalOfZeroToHaveAPaymentPlanConfigurationMustBeGreaterThanZero',
  createdFrom = "createdFrom",
  isPaid = "isPaid",
  isNotPaid = "isNotPaid",
  invoice = "invoice",
  withInternalId = "withInternalId",
  timeline = "timeline",
  timelineOfPayments = "timelineOfPayments",
  installmentInvoice = "installmentInvoice",
  paymentIsOverdue = "paymentIsOverdue",
  paymentIsOverdueBy = "paymentIsOverdueBy",
  paymentsDone = "paymentsDone",
  paymentsHistory = "paymentsHistory",
  payment = "payment",
  penalty = "penalty",
  totalOfDaysLeftToPay = "totalOfDaysLeftToPay",
  accumulatedInterests = "accumulatedInterests",
  totalToPay = "totalToPay",
  baseTotal = "baseTotal",
  addAPayment = "addAPayment",
  didPaymentWasOverdue = "didPaymentWasOverdue",
  didPaymentPaidAnOverdueInstallment = "didPaymentPaidAnOverdueInstallment",
  daysThatInstallmentInvoiceWasOverdue = "daysThatInstallmentInvoiceWasOverdue",
  appliedInterests = "appliedInterests",
  penaltyApplied = "penaltyApplied",
  wasPaidAt = "wasPaidAt",
  wasPaidWithPayment = "wasPaidWithPayment",
  paymentNumber = "paymentNumber",
  paidTheNextInstallmentPayments = "paidTheNextInstallmentPayments",
  makeAPayment = "makeAPayment",
  pay = "pay",
  forgotPassword = "forgotPassword",
  weAreValidatingYourCode = "weAreValidatingYourCode",
  weAlreadySentYouAnEmailWithTheCode = "weAlreadySentYouAnEmailWithTheCode",
  pleaseVerifyYourEmail = "pleaseVerifyYourEmail",
  enterTheCode = "enterTheCode",
  weAreSoSorry = "weAreSoSorry",
  yourCodeAreNotValid = "yourCodeAreNotValid",
  yourCodeWasAlreadyUsed = "yourCodeWasAlreadyUsed",
  pleaseLoginWithYourNewCredentials = "pleaseLoginWithYourNewCredentials",
  email = "email",
  password = "password",
  login = "login",
  setANewPassword = "setANewPassword",
  thePasswordsDoesntMatch = "thePasswordsDoesntMatch",
  iForgotMyPassword = "iForgotMyPassword",
  useYourCredentialsToStartANewDay = "useYourCredentialsToStartANewDay",
  wrongCredentials = "wrongCredentials",
  sendMyAndEmailToResetMyPassword = "sendMyAndEmailToResetMyPassword",
  goToLogin = "goToLogin",
  changePassword = "changePassword",
  reenterPassword = "reenterPassword",
  passwordIsRequired = "passwordIsRequired",
  thisFieldIsRequired = "thisFieldIsRequired",
  thisFieldMustBeLength = "thisFieldMustBeLength",
  characterss = "characters",
  somethingWentWrong = "somethingWentWrong",
  passwordChangedSuccessfully = "passwordChangedSuccessfully",
  goLoginWithYourNewCredentials = "goLoginWithYourNewCredentials",
  customerManagement = "customerManagement",
  customerCompanies = "customerCompanies",
  createdAtTo = "createdAtTo",
  searchBy = "searchBy",
  name = "name",
  addressInformation = "addressInformation",
  contactInformation = "contactInformation",
  totalOfInvoices = "totalOfInvoices",
  unpaidInvoices = "unpaidInvoices",
  fullAddress = "fullAddress",
  zipCode = "zipCode",
  line2 = "line2",
  phoneNumber = "phoneNumber",
  secondPhoneNumber = 'secondPhoneNumber',
  cellPhone = 'cellPhone',
  paid = 'paid',
  all = 'all',
  notPaid = 'notPaid',
  isFlexible = 'isFlexible',
  isFlexibleDescription = 'isFlexibleDescription'
}

export default Localization
